import { template as template_fb9732fda2a840509bee68d3e90d55b8 } from "@ember/template-compiler";
const FKText = template_fb9732fda2a840509bee68d3e90d55b8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
