import { template as template_14614884ec1a41528303a9162b436fe9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_14614884ec1a41528303a9162b436fe9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
